import React from 'react'
import { render } from 'react-dom'

import './styles/main.scss'
import Max from './assets/summer_of_max.jpg'
import Free from './assets/freemax.jpg'
import Sum from './assets/maxsummy.jpg'
import OG from './assets/og.jpg'
import Paddle from './assets/paddle.jpg'
import Skin from './assets/skinemax.jpg'
import Smash from './assets/smash.jpg'
import Beach from './assets/thebeach.jpg'
import Jones from './assets/jones.jpg'
import Alien from './assets/alien.jpg'

class App extends React.Component {
  render () {
    return (
      <div>
        <img src={Max} alt='Summer of Max' />
        <img src={Beach} />
        <img src={Free} />
        <img src={Sum} />
        <img src={Paddle} />
        <img src={Skin} />
        <img src={Smash} />
        <img src={Jones} />
        <img src={Alien} />
        <img src={OG} />
      </div>
    )
  }
}

render(<App />, document.getElementById('app'))

if (module.hot) {
  module.hot.accept()
}
